(function($) {

  console.log('ready');

  $(document).ready(function() {
    $('#fullpage').fullpage({
      licenseKey: '2756A2CF-DC644538-8B00BD1A-CA20F1E7',
      autoScrolling: true,
      scrollHorizontally: true
    });




    $(".navbar-burger").on("click", function() {
      // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
      $(".navbar-burger").toggleClass("is-active");
      $(".navbar-menu").toggleClass("is-active");

    });

    $(".navbar-item").on("click", function() {
      // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
      $(".navbar-burger").removeClass("is-active");
      $(".navbar-menu").removeClass("is-active");

    });

    $("a.scrollLink").click(function(event) {
      event.preventDefault();
      $("html, body").animate({
        scrollTop: $($(this).attr("href")).offset().top
      }, 500);
    });

    mapboxgl.accessToken = 'pk.eyJ1IjoiZGFuc2UxZGVuIiwiYSI6ImNqczEyZW94NjFhOTczeW85NjBrNXY1N3QifQ.iybJnuHy3D1oSjF5vE3fSg';
    var map = new mapboxgl.Map({
      container: 'weddingMap',
      style: 'mapbox://styles/danse1den/ck04mq5de0d8o1cmhtnd9ef39',
      center: [-118.2858148, 34.0728911],
      zoom: 12
    });
    map.addControl(new mapboxgl.NavigationControl());
    // disable map zoom when using scroll
    map.scrollZoom.disable();
    //methods
    // $.fn.fullpage.setAllowScrolling(false);
  })


})(jQuery);
